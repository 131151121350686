import React from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fab from "@material-ui/core/Fab";
import PlayIcon from "@material-ui/icons/PlayArrowSharp";
import NextIcon from "@material-ui/icons/SkipNextSharp";
import SkipIcon from "@material-ui/icons/FastForwardSharp";
import Restore from "@material-ui/icons/Restore";
import StopIcon from "@material-ui/icons/StopSharp";
import HomeIcon from "@material-ui/icons/Home";
import BackIcon from "@material-ui/icons/ArrowBack";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Page404 from "../components/404";

const useStyles = makeStyles({
  root: {
    width: "100%",
    bottom: "20px",
    position: "fixed",
  },
  home: {
    background: "#F50057", //HGA red
    "&:hover": {
      background: "#BA0948", //darken HGA red
    },
    color: "#FFFFFF",
    position: "fixed",
    top: "80px",
    right: "15px",
  },
  back: {
    background: "#568536", //HGA green
    "&:hover": {
      background: "#385723", //darken HGA green
    },
    color: "#FFFFFF",
    position: "fixed",
    top: "145px",
    right: "15px",
  },
});

function CounterControl({ controlProps }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  if (controlProps) {
    console.log("controlProps ada = " + JSON.stringify(controlProps));
    return (
      <Layout>
        <SEO title="Loket" />
        <br />
        <Typography variant="h3" align="center">
          {controlProps.type}
        </Typography>
        <Typography variant="h4" align="center">
          {controlProps.name}
        </Typography>
        <br />
        <Divider variant="middle" />
        <br />
        <Typography variant="h3" align="center">
          Antrian sekarang:{" "}
        </Typography>
        <Typography variant="h1" align="center">
          C005
        </Typography>
        <br />
        <Divider variant="middle" />
        <br />
        <Menu
          id="skipped"
          anchorEl={anchorEl}
          disableAutoFocusItem
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>C001</MenuItem>
          <MenuItem onClick={handleClose}>C003</MenuItem>
          <MenuItem onClick={handleClose}>C004</MenuItem>
        </Menu>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={classes.root}
        >
          <BottomNavigationAction label="Mulai" icon={<PlayIcon />} />
          <BottomNavigationAction label="Selesai" icon={<StopIcon />} />
          <BottomNavigationAction label="Selanjutnya" icon={<NextIcon />} />
          <BottomNavigationAction label="Lewati" icon={<SkipIcon />} />
          <BottomNavigationAction
            label="Ke yang Terlewat"
            icon={<Restore />}
            aria-controls="skipped"
            aria-haspopup="true"
            onClick={handleClick}
          />
        </BottomNavigation>
        <Link to="/">
          <Fab aria-label="Home" size="large" className={classes.home}>
            <HomeIcon />
          </Fab>
        </Link>
        <Link to="/settings/">
          <Fab aria-label="Back" size="large" className={classes.back}>
            <BackIcon />
          </Fab>
        </Link>
      </Layout>
    );
  }
  return <Page404 />;
}

const mapStateToProps = state => {
  return {
    controlProps: state.global.controlProps,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CounterControl);
